<template>
  <div class="page">
    <div class="screen" id="screen">
      <!--- 融合模式 -->
      <div class="page_introduce" id="scrollinto_1">
        <div class="introduce_top">
          <div class="title">
            <div class="line"></div>

            <div class="txt">融合模式</div>

            <div class="line"></div>
          </div>

          <div class="sub_title">HOT SELLING</div>
        </div>

        <div class="introduce_content">
          <el-row :gutter="30">
            <el-col :span="4.8">
              <div class="grid-content bg-purple">
                <div>
                  <img class="top_img" src="../assets/home/home_live.png" />
                </div>
                <div class="title">社区直播</div>
                <div class="subtitle">政策导向</div>
                <div class="subtitle">生活娱乐</div>
                <div class="subtitle">多种线上支付方式</div>
                <div class="btn" @click="goPageModule(0)">立即查看</div>
              </div>
            </el-col>
            <el-col :span="4.8">
              <div class="grid-content bg-purple">
                <div>
                  <img class="top_img" src="../assets/home/home_qs.png" />
                </div>
                <div class="title">时尚轻食</div>
                <div class="subtitle">随点随到</div>
                <div class="subtitle">时尚好吃</div>
                <div class="subtitle">品牌加盟</div>
                <div class="btn" @click="goPageModule(1)">立即查看</div>
              </div>
            </el-col>
            <el-col :span="4.8">
              <div class="grid-content bg-purple">
                <div>
                  <img class="top_img" src="../assets/home/home_ziyuantong.png" />
                </div>
                <div class="title">社区资源通</div>
                <div class="subtitle">一键报修</div>
                <div class="subtitle">工单智能派发</div>
                <div class="subtitle">处理流程透明化</div>
                <div class="btn" @click="goPageModule(2)">立即查看</div>
              </div>
            </el-col>
            <el-col :span="4.8">
              <div class="grid-content bg-purple">
                <div>
                  <img class="top_img" src="../assets/home/home_caoshi.png" />
                </div>
                <div class="title">仓储式超市</div>
                <div class="subtitle">租赁情况跟踪</div>
                <div class="subtitle">客户关系管理</div>
                <div class="subtitle">续签及到期提醒</div>
                <div class="btn" @click="goPageModule(3)">立即查看</div>
              </div>
            </el-col>

            <el-col :span="4.8">
              <div class="grid-content bg-purple">
                <div>
                  <img class="top_img" src="../assets/home/home_jieneng.png" />
                </div>
                <div class="title">智慧节能</div>
                <div class="subtitle">智慧回收</div>
                <div class="subtitle">循环利用</div>
                <div class="subtitle">绿色家园化</div>
                <div class="btn">立即查看</div>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="more_btn" @click="goPageMore()">更多模块 > ></div>
      </div>

      <!---社区直播 -->
      <div class="page_live" id="scrollinto_2">
        <div class="left">
          <div class="title">社区直播，新自媒体娱乐，未来新交流</div>
          <div class="intro">
            通过未来+云平台实现物业能耗，巡检、门禁、停车、监控、充电桩、梯控等硬件层面管理全部智能一体化，用户缴费、保修、投诉、通知等服务全部可通过手机线上完成，快捷的享受全方位服务。
          </div>
          <div class="intro">
            未来+智慧物业支持PC网页端、微信小程序，APP等多渠道接入，为10000+客户提供优质专业服务，积累了大量的行业经验与管理模型。
          </div>
        </div>

        <div class="right">
          
        </div>
      </div>

      <!---物业小区 -->
      <div class="page_property" id="scrollinto_3">
        <div class="introduce_top">
          <div class="title">
            <div class="line"></div>

            <div class="txt">未来+深度覆盖800+小区</div>

            <div class="line"></div>
          </div>

          <div class="sub_title">板块自由组合，专属于您的智慧物业方案</div>
        </div>

        <div class="content_info">
          <el-row :gutter="30">
            <el-col :span="6">
              <div class="grid-property">
                <img class="img" src="../assets/home/home_xiaoqu1.png"/>
                <div class="name">蓝竹湾公寓</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-property">
                <img class="img" src="../assets/home/home_xiaoqu2.png"/>
                <div class="name">白金公寓</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-property">
                <img class="img" src="../assets/home/home_xiaoqu3.png"/>
                <div class="name">红星嘉园小区</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-property">
                <img class="img" src="../assets/home/home_xiaoqu4.png"/>
                <div class="name">金桂嘉园小区</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <!--- 物业管理系统 -->
      <div class="page_manage" id="scrollinto_4">
        <div class="introduce_top">
          <div class="title">
            <div class="line" style="background-color: #3ca35f"></div>

            <div class="txt" style="color: #3ca35f">未来+架构</div>

            <div class="line" style="background-color: #3ca35f"></div>
          </div>

          <div class="sub_title" style="color: #3ca35f">物业管理系统</div>
        </div>

        <div class="manage_content">
          <div class="manage_left">
            <div class="box">
              <div class="title">未来+APP</div>
              <div class="content">
                业主无需下载APP,直接通过微信公众号/小程序进行缴费、保修等常规操作
              </div>
            </div>
            <div class="box">
              <div class="title">未来+后台管理系统</div>
              <div class="content">
                业主无需下载APP,直接通过微信公众号/小程序进行缴费、保修等常规操作
              </div>
            </div>

            <div class="box">
              <div class="title">未来+商家端</div>
              <div class="content">
                业主无需下载APP,直接通过微信公众号/小程序进行缴费、保修等常规操作
              </div>
            </div>
          </div>

          <div class="manage_right">
            <img class="img1" src="../assets/home/home_app1.png"/>
            <img class="img2" src="../assets/home/home_app2.png"/>
            <img class="img3" src="../assets/home/home_app3.png"/>
          </div>
        </div>
      </div>

      <!--- 全国布局 -->
      <div class="page_manage_num">
        <div class="introduce_top">
          <div class="title">
            <div class="line" style="background-color: #fff"></div>

            <div class="txt" style="color: #fff">全国布局</div>

            <div class="line" style="background-color: #fff"></div>
          </div>

          <div class="sub_title" style="color: #fff">物业管理系统</div>
        </div>

        <div class="num_info">
          <el-row :gutter="180">
            <el-col :span="8">
              <div class="grid-manage">
                <img class="num_icon" src="../assets/home/home_num1.png"/>
                <div class="txt1">服务客户</div>
                <div class="txt2">
                  1500W
                  <span class="txt3">+</span>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-manage">
                <img class="num_icon" src="../assets/home/home_num2.png"/>
                <div class="txt1">服务业主</div>
                <div class="txt2">
                  1500W
                  <span class="txt3">+</span>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-manage">
                <img class="num_icon" src="../assets/home/home_num3.png"/>
                <div class="txt1">合作伙伴</div>
                <div class="txt2">
                  2500W
                  <span class="txt3">+</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <!--- 生态合作 -->
      <div class="page_customer" id="scrollinto_5">
        <div class="introduce_top">
          <div class="title">
            <div class="line"></div>
            <div class="txt">生态合作</div>
            <div class="line"></div>
          </div>

          <div class="sub_title" style="color: #333">服务全国2500+客户</div>
        </div>

        <div class="customer_info">
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      top: "100",
      menuList: ["首页", "解决方案", "关于我们", "联系我们"],
      menu_index: 0,
      listBoxState: true,
      left: 90,
      width: 0,
      iWidths: 0,
      norWidtList: [36, 72, 72, 72],
      activeWidthList: [60, 120, 120, 120],
     
    };
  },
  watch: {
    // menu_index:{
    //   handler(newVal){
    //      this.$nextTick(() => {
    //     this.tabLineChange();
    //    });

    //   },
    //   immediate:true
    // }
    menu_index(newVal) {
      this.menu_index = newVal;
      this.$nextTick(() => {
        this.tabLineChange();
      });
    },
  },

  mounted() {
    this.tabLineChange();
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },

  methods: {
    pre() {
      var data = this.$refs.mySwiper.$swiper;
      this.$refs.cardShow.prev();
      data.slidePrev(50, false);
    },
    next() {
      var data = this.$refs.mySwiper.$swiper;
      this.$refs.cardShow.next();
      data.slideNext(50, false);
    },
    handleClickSlide() {
      var data = this.$refs.mySwiper.$swiper.realIndex;
      this.$refs.cardShow.setActiveItem(data);
    },
    show(e) {
      this.$viewerApi({
        images: [e.target.currentSrc],
      });
    },
    change(e) {},

    tabLineChange() {
      let data = document.getElementsByClassName("menu_item");

      let activeData = document.getElementsByClassName("menu_item_active");
      let space = 90;

      this.width = this.activeWidthList[this.menu_index];
      space = this.menu_index * 90 + 90;
      this.left =
        this.norWidtList.slice(0, this.menu_index).reduce((num, item) => {
          return num + item;
        }, 0) + space;
    },

    goPageMore(){
      this.$router.push({
          path: "/wyModuleListModel"
        });

    },

    goPageModule(index){
      if(index == 0){
        this.$router.push({
          path: "/media"
        });
      }else if(index ==1){
        this.$router.push({
          path: "/qsModel"
        });
      }else if(index ==2){
        this.$router.push({
          path: "/Resource"
        });
      }else if(index ==3){
        this.$router.push({
          path: "/supermarket"
        });
      }

    },

    changeMenu(index, e) {
      // if (index == 6) {
      //   this.bol = true;
      //   return;
      // }
      if (index != this.menu_index) {
        this.menu_index = index;
      }

      // let idS = "scrollinto_" + index;
      // document.getElementById(idS).scrollIntoView({
      //   behavior: "smooth", // 平滑过渡
      //   block: "center" // 上边框与视窗顶部平齐。默认值
      // });
      // this.listBoxState = false; //在data中定义一个初始化值true

      // let timeId;
      // clearTimeout(timeId);
      // timeId = setTimeout(() => {
      //   this.listBoxState = true;
      // }, 1000);
    },

    //滚动右侧事件
    // scrollToTop() {
    //   var domHight = document.body.offsetHeight;
    //   let d = document.documentElement.scrollTop;
    //   this.top = "150";

    //   if (d == 0) {
    //     this.top = "100";
    //   }

    //   // dom滚动位置
    //   var scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //   if (this.listBoxState) {
    //     this.menuList.forEach((v, i) => {
    //       if (i == 6) return;
    //       let idS = "scrollinto_" + i;
    //       // 获取监听元素距离视窗顶部距离
    //       var offsetTop = document.getElementById(idS).offsetTop;
    //       // 获取监听元素本身高度
    //       var scrollHeight = document.getElementById(idS).scrollHeight;

    //       // 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
    //       // 则表示页面已经滚动到可视区了。
    //       if (scrollTop >= offsetTop && scrollTop <= offsetTop + scrollHeight) {
    //         // 导航栏背景色选中
    //         this.menu_index = i;
    //       }
    //     });
    //   }
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/.el-button--success {
  background-color: rgba(31, 45, 61, 0.11);
  color: #fff;
  border-color: rgba(31, 45, 61, 0.11);
}
/deep/.el-carousel__arrow {
  display: none;
}
.page {

  .screen {
  }
}
.magetop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 1920px;
  transform-origin: 0 0;
  .page_top {
    width: 100%;
    height: 80px;
    z-index: 100;
    background: #fff;
    overflow: hidden;
    // opacity: 1;
  }
}

.top_menu_img {
  position: sticky;
  top: 30px;
  left: 3%;
  z-index: 100;
  float: left;
  img {
    width: 112px;
    height: 42px;
  }
}

.top_menu {
  position: sticky;
  // right: 10%;
  left: 10%;
  top: 10px;
  float: left;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  z-index: 100;
}

.menu_item {
  margin-left: 90px;
  font-size: 18px;
  font-weight: 400;
  color: 3ca35f;
  transition: all 0.3s;
  cursor: pointer;
}

.menu_item_active {
  margin-left: 90px;
  font-size: 30px;
  font-weight: bold;
  color: #3ca35f;
}

.page_introduce {
  width: 1210px;
  margin: auto;

  padding-top: 67px;

  .introduce_content {
    margin-top: 86px;
    .grid-content {
      width: 218px;
      height: 613px;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(6, 0, 1, 0.12);
      .top_img {
        width: 94px;
        height: 94px;
        margin: auto;
        margin-top: 86px;
      }
      .title {
        height: 22px;
        font-size: 22px;
        text-align: center;
        font-weight: 400;
        color: #333333;
        margin-top: 90px;
        margin-bottom: 53px;
      }
      .subtitle {
        margin-bottom: 35px;
        height: 19px;
        font-size: 18px;
        text-align: center;
        font-weight: 400;
        color: #333333;
      }
      .btn {
        width: 135px;
        height: 47px;
        background: #3ca35f;
        border-radius: 5px;
        color: #fff;
        margin: auto;
        line-height: 47px;
      }
    }
  }

  .more_btn {
    width: 125px;
    height: 41px;
    background: #ffffff;
    border: 1px solid #45a164;
    border-radius: 5px;
    line-height: 41px;
    color: #45a164;
    margin: auto;
    margin-top: 50px;
  }
}

.introduce_top {
  .title {
    display: flex;
    align-items: center;
    justify-content: center;

    .txt {
      // width: 142px;
      height: 31px;
      line-height: 31px;
      font-size: 32px;
      padding-left: 15px;
      padding-right: 15px;
      font-weight: bold;
      color: #333333;
    }

    .line {
      width: 48px;
      height: 2px;
      background-color: #333333;
    }
  }
  .sub_title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #999;
  }
}

.page_live {
  width: 1200px;
  height: 407px;
  background: #f0f6ff;
  margin: auto;
  margin-top: 111px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url('../assets/home/home_live_bg.png');
  background-size: 100% 100%;

  .left {
    width: 510px;
    margin-left: 76px;
    .title {
      margin-bottom: 40px;
      text-align: left;
      height: 24px;
      font-size: 23px;
      font-weight: bold;
      color: #3ca35f;
    }
    .intro {
      text-align: left;
      text-indent: 20px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 31px;
    }
  }
  .right {
   
    margin-right: 104px;
    img {
      width: 383px;
      height: 277px;
    }
  }
}

.page_property {
  width: 1210px;
  margin: auto;
  margin-top: 279px;
  .content_info {
    margin-top: 88px;
    .grid-property {
      width: 270px;
      height: 234px;
      background: #ffffff;
      border: 1px solid #ededed;
      font-size: 0px;
      .img {
        width: 270px;
        height: 180px;
        background: red;
      }
      .name {
        height: 54px;
        font-size: 16px;
        font-weight: 400;
        color: #787878;
        line-height: 54px;
      }
    }
  }
}

.page_manage {
  margin-top: 238px;
  .manage_content {
    margin-top: 69px;
    width: 1920px;
    height: 655px;
    background: url('../assets/home/home_manage_bg.png');
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .manage_left {
      width: 480px;
      .box {
        width: 480px;
        background: #ffffff;
        box-shadow: 0px 0px 14px 0px rgba(6, 0, 1, 0.12);
        border-radius: 7px;
        border-left: 11px solid #3ca35f;
        margin-bottom: 17px;
        transition: 0.3s;

        .title {
          padding-top: 20px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          margin-left: 80px;
          margin-right: 50px;
        }
        .content {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          margin-top: 15px;
          margin-left: 80px;
          margin-right: 50px;
          padding-bottom: 25px;
        }
      }
      .box:hover{
        border-left: 20px solid #3ca35f;
        transition: 0.3s;
      }
    }
    .manage_right {
      width: 708px;
      margin-top: 20px;

      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .img1 {
        margin-left: 74px;
        width: 133px;
        height: 266px;
        
      }
      .img2 {
        margin-left: 27px;
        width: 190px;
        height: 380px;
       
      }
      .img3 {
        margin-left: 27px;
        width: 280px;
        height: 175px;
        
      }
    }
  }
}

.page_manage_num {
  padding-top: 118px;
  background: url('../assets/home/home_num_bg.png');
  background-size: 100% 100%;
  width: 1920px;
  height: 610px;
  .num_info {
    width: 900px;
    margin: auto;
    margin-top: 72px;
    .grid-manage {
      width: 180px;
      height: 279px;
      text-align: left;
      .num_icon {
        width: 115px;
        height: 101px;
      
      }
      .txt1 {
        margin-top: 72px;
        height: 18px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
      }
      .txt2 {
        margin-top: 20px;
        height: 33px;
        font-size: 41px;
        font-weight: bold;
        color: #ffffff;
        .txt3 {
          height: 33px;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }
}

.page_customer {
  margin-top: 124px;
  .customer_info {
    width: 1329px;
    margin: auto;
    margin-top: 127px;
    margin-bottom: 186px;
    height: 552px;
    background: url('../assets/home/home_customer_bg.png');
    background-size: 100% 100%;
    
   
  }
}

.tab-activation-item {
  position: absolute;
  height: 30px;
  // width: 36px;
  top: 54px;
  left: 0;
  z-index: 9;
  transition: all 0.3s linear;
  border-radius: 3px;
}
.tab_line {
  width: 36px;
  height: 5px;
  background: #3ca35f;
  border-radius: 3px;
  margin: 10px auto 0;
}
.tab_view {
  min-width: 100%;
  height: 80px;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  overflow: hidden;
  position: relative;
}
</style>
